import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><em parentName="p">{`Deload Week`}</em></p>
    <p>{`Plank 3×1:00`}</p>
    <p>{`Side Plank 3x:30`}</p>
    <p>{`DB Deadlifts 3×12`}</p>
    <p>{`then,`}</p>
    <p>{`50 Calorie Ski Erg for time.`}</p>
    <p>{`Rest 1:00 then,`}</p>
    <p>{`Death by:`}</p>
    <p>{`1-KB SDHP’s (53/35)(RX+ 70/53)`}</p>
    <p>{`1-KB Facing Burpee`}</p>
    <p><em parentName="p">{`*`}{`increase by 1 rep each exercise, each minute until failure.`}</em></p>
    <p>{`Rest 1:00 then,`}</p>
    <p>{`50 Calorie Ski Erg for time.`}</p>
    <p>{`Scores = time/rounds/time`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      